import { createEvent, createStore, restore, sample } from 'effector';
import { $endpointManager } from '@/store/webrtc/endpoints';
import { $canvas, $layoutType } from '@/store/layout';
import { MediaSettingsStore } from '@/store/endpointMedia/MediaSettingsStore';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { meetingStore } from '@/store/meeting';
import { CanvasDef } from '@/helpers/layouts';
import { generateTilerArea } from '@/store/webrtc/generateTilerArea';
import { updatePinState } from '@/helpers/pin';

const VAD_UPDATE_DELAY = 1000;
const VIDEO_SIZE_UPDATE_DELAY = 400;

const reorderEndpointMedias = createEvent();
// TODO: possibly useless now. Check why?
const updateVideoSettings = createEvent<RenderVideoStore[]>();
const makeVideoFullscreen = createEvent<string>();
const render = createEvent<CanvasDef>();
const enableRemoteVideo = createEvent<{ endpointId: string; active: boolean }>();
const openFullScreen = createEvent<string>();
const requestVideoSizeEvent = createEvent<{
  endpointId: string;
  width: number;
  height: number;
}>();

// TODO: check $mediaSettings after rebase to new alpha version. Sometimes store is empty
const $mediaSettings = createStore<MediaSettingsStore>({});

/*
  The store saves the status of the endpoint videos which has been disabled in the video slot menu (SpeakerButton)
*/
const $endpointVideoDisabledByUser = createStore<string[]>([]);
const enableEndpointVideoByUser = createEvent<string[]>();

const toggleAllIncomingVideo = createEvent<boolean>();
const $isAllIncomingVideoDisabled = restore(toggleAllIncomingVideo, false);

const disableEndpointVideoByUser = createEvent<string[]>();

export {
  VAD_UPDATE_DELAY,
  VIDEO_SIZE_UPDATE_DELAY,
  $mediaSettings,
  $endpointVideoDisabledByUser,
  $isAllIncomingVideoDisabled,
  disableEndpointVideoByUser,
  enableEndpointVideoByUser,
  reorderEndpointMedias,
  updateVideoSettings,
  makeVideoFullscreen,
  enableRemoteVideo,
  openFullScreen,
  toggleAllIncomingVideo,
  requestVideoSizeEvent,
};
