import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0585b41e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "button", {}, undefined, true)
    ])
  ]))
}