import { ref, Ref } from 'vue';

type VIObservable<T> = {
  value: T;
  watch(observer: (event: T) => void): void;
  unwatch(observer: (event: T) => void): void;
  clear(): void;
};

const useVIObservable = <T>(
  observable: T
): T extends VIObservable<infer InnerType> ? Ref<InnerType> : never => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const obs = observable as VIObservable<any>;
  const vueRef = ref(obs.value);

  obs.watch((value) => {
    vueRef.value = value;
  });

  return vueRef;
};

export { VIObservable, useVIObservable };
