import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65329516"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toggle", {'disable': _ctx.isDisabled }])
  }, [
    _createElementVNode("input", {
      class: _normalizeClass(["toggle-button", _ctx.size]),
      type: "checkbox",
      id: _ctx.value,
      disabled: _ctx.isDisabled
    }, null, 10, _hoisted_1)
  ], 2))
}