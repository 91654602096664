
  import { defineComponent, onMounted, ref } from 'vue';
  import LayoutItem from '@/components/LayoutItem.vue';
  import { closeDrawer } from '@/store/drawer';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { Select, Button, Typography } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition.cjs';
  import { useI18n } from 'vue-i18n';
  import { $layoutType, changeLayoutType } from '@/store/layout';
  import { LayoutType } from '@/helpers/layouts';

  interface LayoutSubType {
    name: string;
    description: string;
    layoutScreen: string;
    // type: AreaStore;
    type: string;
  }

  interface LayoutOptions {
    label: string;
    value: string;
    layoutItems: LayoutSubType[];
  }
  export default defineComponent({
    components: { LayoutItem, Select, Button, Typography },
    setup() {
      const { t } = useI18n();
      const layoutItems: LayoutOptions[] = [
        {
          label: 'Default',
          value: 'Default',
          layoutItems: [
            {
              name: t('layout.types.grid.name'),
              description: t('layout.types.grid.description'),
              layoutScreen: require('@/assets/images/grid.svg'),
              type: 'grid',
            },
            {
              name: t('layout.types.tribune.name'),
              description: t('layout.types.tribune.description'),
              layoutScreen: require('@/assets/images/tribune.png'),
              type: 'tribune',
            },
            {
              name: t('layout.types.demonstration.name'),
              description: t('layout.types.demonstration.description'),
              layoutScreen: require('@/assets/images/demonstration.png'),
              type: 'demonstration',
            },
          ],
        },
        /*{
          label: 'Education',
          value: 'Education',
          layoutItems: [
            {
              name: 'Layout name',
              description: ' Description layout text goes here Help or instruction text goes here ',
              layoutScreen: require('@/assets/education_layout_name1.png'),
              type: 'demonstration',
            },
            {
              name: 'Whiteboard mode',
              description: 'Enables you to focus attention on the whiteboard.',
              layoutScreen: require('@/assets/whiteboard_mode.png'),
              type: 'demonstration',
            },
            {
              name: 'Layout name',
              description: ' Description layout text goes here Help or instruction text goes here ',
              layoutScreen: require('@/assets/education_layout_name3.png'),
              type: 'demonstration',
            },
          ],
        },*/
        /*{
          label: 'Webinars',
          value: 'Webinars',
          layoutItems: [
            {
              name: 'Layout name',
              description: ' Description layout text goes here Help or instruction text goes here ',
              layoutScreen: require('@/assets/webinars_layout1.png'),
              type: 'demonstration',
            },
            {
              name: 'Layout name',
              description: 'Description layout text goes here Help or instruction text goes here',
              layoutScreen: require('@/assets/webinars_layout2.png'),
              type: 'demonstration',
            },
          ],
        },*/
      ];
      const layoutItemsMobile: LayoutSubType[] = [
        /*{
          name: '2 users on screen',
          description: 'The screen always shows 2 equal users max with scroll',
          layoutScreen: require('@/assets/2users_layout_mobile.png'),
          type: 'demonstration',
        },
        {
          name: '4 users on screen',
          description: 'The screen always shows 2 equal users max with scroll',
          layoutScreen: require('@/assets/4users_layout_mobile.png'),
          type: 'demonstration',
        },*/
        {
          name: t('layout.types.grid.name'),
          description: t('layout.types.grid.description'),
          layoutScreen: require('@/assets/6users_layout_mobile.png'),
          type: 'grid',
        },
        {
          name: t('layout.types.tribune.name'),
          description: t('layout.types.tribune.description'),
          layoutScreen: require('@/assets/tribune_layout_mobile.png'),
          type: 'tribune',
        },
        {
          name: t('layout.types.demonstration.name'),
          description: t('layout.types.demonstration.description'),
          layoutScreen: require('@/assets/demonstration_layout_mobile.png'),
          type: 'demonstration',
        },
      ];
      const layoutType = useStore($layoutType);
      const activeItem = ref(layoutItems[0]);
      const activeLayout = ref<string | null>(layoutItems[0].layoutItems[0].name);
      const activeLayoutMobile = ref<string | null>(layoutItemsMobile[0].name);
      const content = ref(layoutItems);
      const { isMobileScreen } = useIsMobileScreen();

      const onSelectActive = (type: LayoutType) => {
        changeLayoutType(type);
      };
      onMounted(() => {
        activeLayout.value = layoutType.value;
      });
      return {
        layoutType,
        closeDrawer,
        content,
        activeItem,
        activeLayout,
        layoutItemsMobile,
        onSelectActive,
        activeLayoutMobile,
        isMobileScreen,
        t,
      };
    },
  });
