
  import { defineComponent } from 'vue';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import { Icon, Button } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  export default defineComponent({
    name: 'FileInput',
    components: {
      DownloadedFiles,
      Icon,
      Button,
    },
    props: {
      isDefault: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const { t } = useI18n();
      return {
        t,
      };
    },
  });
