
  import { defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { closeDrawer } from '@/store/drawer';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'MobileDownloadFile',
    components: {
      Button,
      DownloadedFiles,
    },
    props: {
      isEmpty: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const { t } = useI18n();
      return {
        t,
        closeDrawer,
      };
    },
  });
