
  import { mockName } from '@/helpers/mockName';
  import { $isGuest } from '@/store/auth';
  import { useStore } from 'effector-vue/composition';
  import { computed, defineComponent, ref } from 'vue';

  export default defineComponent({
    name: 'SignInAccount',
    components: {},
    // isProfile - use to change info
    props: {
      avatar: {
        type: String,
        default: require('@/assets/images/mocks/icon-A.svg'),
      },
      userName: {
        type: String,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
      isProfile: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const avatarMockName = computed(() => mockName(props.userName));
      const isGuest = useStore($isGuest);
      const emailInfo = computed(() => (isGuest.value ? 'Guest' : props.email));
      const hasLoadError = ref(false);
      const handleErrorOnLoad = () => {
        hasLoadError.value = true;
      };

      return {
        avatarMockName,
        emailInfo,
        hasLoadError,
        handleErrorOnLoad,
      };
    },
  });
