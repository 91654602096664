import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90d9b674"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emoji-panel" }
const _hoisted_2 = { class: "emoji-wrap" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactions.emojilist, (icon, type) => {
        return (_openBlock(), _createBlock(_component_Tooltip, {
          key: type,
          text: type
        }, {
          button: _withCtx(() => [
            _createElementVNode("div", {
              class: "emoji",
              onClick: ($event: any) => (_ctx.animate(type))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass({animate:!!~_ctx.animationTimeouts[type], color:_ctx.reactions.localReactions[type]})
              }, _toDisplayString(icon), 3)
            ], 8, _hoisted_3)
          ]),
          _: 2
        }, 1032, ["text"]))
      }), 128))
    ])
  ]))
}