
  import { computed, defineComponent } from 'vue';
  import { Input, Select, Button } from '@voximplant/spaceui';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  import ToggleWithText from '@/components/toggles/ToggleWithText.vue';
  import Sound from '@/components/decorative-elements/Sound.vue';
  import { openDrawer, closeDrawer } from '@/store/drawer';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { $devices, setActiveDevices, setVideoQuality } from '@/store/devices/index';
  import {
    $mirrorStore,
    MEDIA_BUTTON_DEBOUNCE_GAP,
    requestMirrorStream,
  } from '@/store/mirrorMedia/index';
  import { authStore } from '@/store/auth';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  import { debounce } from '@/helpers/debounce';
  import { VideoQuality } from '@/store/devices/VideoQuality';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'MobileExpandedSettings',
    components: {
      Input,
      Select,
      Button,
      DownloadedFiles,
      FileInput,
      Sound,
      SignInAccount,
      ToggleWithText,
    },
    props: {
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const auth = useStore(authStore);
      const mirrorStore = useStore($mirrorStore);
      const updateVideoDevice = (e: VideoDeviceInfo) => {
        if (mirrorStore.value.videoPreview) {
          requestMirrorStream({
            selectedAudioDevice: $devices.getState().selectedAudioDevice,
            selectedVideoDevice: e,
          });
        } else {
          setActiveDevices({ selectedVideoDevice: e });
        }
      };
      const videoQuality = Object.values(VideoQuality);
      const updateVideoQuality = (e: { label: string; value: number }) => {
        setVideoQuality(e);
        if (mirrorStore.value.videoPreview) {
          requestMirrorStream({
            selectedAudioDevice: $devices.getState().selectedAudioDevice,
            selectedVideoDevice: $devices.getState().selectedVideoDevice,
          });
        }
      };
      const updateAudioDevice = debounce((e: AudioDeviceInfo) => {
        if (devices.value.audioEnabled) {
          requestMirrorStream({ selectedAudioDevice: e });
        } else {
          setActiveDevices({ selectedAudioDevice: e });
        }
      }, MEDIA_BUTTON_DEBOUNCE_GAP);
      const isAudioDisabled = computed(
        () => !!props.deviceError && props.deviceError !== 'noCamera'
      );

      return {
        openDrawer,
        updateAudioDevice,
        auth,
        devices,
        closeDrawer,
        updateVideoDevice,
        updateVideoQuality,
        isAudioDisabled,
        t,
        videoQuality,
      };
    },
  });
