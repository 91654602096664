type Size = 'large' | 'medium' | 'small';

const VideoSizeCheckpoint: Record<Size, number> = {
  large: 350,
  medium: 150,
  small: 100,
};

const getVideoSlotSize = (area: { width: number; height: number }): Size => {
  const [sizeValue] = Object.entries(VideoSizeCheckpoint).find(([, size]) => area.width > size)!;
  return (sizeValue as Size) || 'small';
};

export { getVideoSlotSize };
