
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'RadioInput',
    components: {},
    props: {
      item: {
        type: String,
      },
      label: {
        type: String,
      },
      name: {
        type: String,
        default: 'radio',
      },
      checked: {
        type: Boolean,
      },
    },
  });
