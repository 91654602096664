import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22b1e83e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mock-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VadBorder = _resolveComponent("VadBorder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mockAvatarUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["avatar", _ctx.videoMockClasses]),
          src: _ctx.mockAvatarUrl
        }, null, 10, _hoisted_2))
      : (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(["user", _ctx.videoMockClasses])
        }, _toDisplayString(_ctx.mockName(_ctx.name)), 3)),
    _createVNode(_component_VadBorder, { hasVad: _ctx.hasVad }, null, 8, ["hasVad"])
  ]))
}