import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69100e60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-download" }
const _hoisted_2 = { class: "download-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "background-wrap"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "background-text" }
const _hoisted_6 = {
  key: 1,
  class: "file-wrap"
}
const _hoisted_7 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadedFiles = _resolveComponent("DownloadedFiles")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "background-image",
              src: require('../../assets/images/no-attach-files-background.png')
            }, null, 8, _hoisted_4),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('fileInput.noFiles')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_DownloadedFiles, { class: "mobile-file" })
          ]))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Button, {
        class: "button-download",
        mode: "primary",
        size: "l",
        onClick: _ctx.closeDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('fileInput.title')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}