
  import { computed, defineComponent, onMounted, onBeforeUnmount, ref, Ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { generateCheckClickOutside } from '@voximplant/spaceui';

  const __default__ = defineComponent({
    name: 'PopUpWithButton',
    components: {
      Button,
    },
    props: {
      name: {
        type: String,
        default: 'ic25-settings',
      },
      size: {
        type: String,
        default: '25px',
      },
      isDefaultButton: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      popUpWidth: {
        type: Number,
        default: 268,
      },
    },
    setup(props) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      const POPUP_WIDTH = props.popUpWidth;
      const isActive = ref(false);
      const PopUpRef = ref<HTMLElement | null>(null);
      const PopUpButtonRef = ref<HTMLElement | null>(null);
      const directionX = ref('right');
      const directionY = ref('top');
      const shearDistance = ref('0px');
      const popupClasses = computed(() => ({
        'left-direction': directionX.value === 'left',
        'bottom-direction': directionY.value === 'bottom',
        'top-direction': directionY.value === 'top',
        'shear-distance': shearDistance.value !== '0px',
      }));
      const checkClickOutside = computed(() =>
        generateCheckClickOutside([PopUpRef, PopUpButtonRef])
      );
      const onClickOutside = (e: MouseEvent) => {
        if (checkClickOutside.value(e)) {
          isActive.value = false;
        }
      };

      const toggleClass = (event: PointerEvent | null) => {
        if (!event) return (isActive.value = false);
        if (window.innerWidth - event.pageX < event.pageX) directionX.value = 'left';
        if (window.innerHeight - event.pageY > event.pageY) directionY.value = 'bottom';
        if (event.pageX + POPUP_WIDTH > window.innerWidth) {
          const difference = event.pageX + POPUP_WIDTH - window.innerWidth;
          shearDistance.value = '-' + difference + 'px'; // blend the popup by a distance that does not fit into the view
          directionX.value = 'right';
        } else {
          shearDistance.value = '0px'; // reset to default value
        }
        if (!props.disabled) isActive.value = !isActive.value;
      };

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          isActive.value = false;
        }
      };

      onMounted(() => {
        document.addEventListener('click', onClickOutside, { capture: true });
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('click', onClickOutside, {
          capture: true,
        });
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      const { buttonMode } = useButtonMode(isActive);
      const { iconProps } = useIconProps(isActive, props.name, props.size);

      return {
        isActive,
        toggleClass,
        PopUpRef,
        PopUpButtonRef,
        buttonMode,
        iconProps,
        popupClasses,
        shearDistance,
        POPUP_WIDTH,
      };
    },
  });
  function useButtonMode(isActive: Ref<boolean>) {
    const buttonMode = computed(() => (isActive.value ? 'secondary' : 'flat'));
    return { buttonMode };
  }
  function useIconProps(isActive: Ref<boolean>, name: string, size: string) {
    const iconColor = computed(() => (isActive.value ? '--sui-purple-500' : '--sui-gray-700'));
    const iconProps = computed(() => ({
      spriteUrl: '/image/videoconf-icons.svg',
      name: name,
      color: iconColor.value,
      width: size,
      height: size,
    }));

    return { iconProps };
  }

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "272c800a": (_ctx.shearDistance)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__