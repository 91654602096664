import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c719767"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layout-item"
}
const _hoisted_2 = { class: "radio-btn" }
const _hoisted_3 = { class: "layout-description" }
const _hoisted_4 = { class: "layout-screen" }
const _hoisted_5 = { class: "layout-screen" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "radio-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (!_ctx.isMobileScreen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RadioButton, {
            label: _ctx.name,
            size: "m",
            value: _ctx.type,
            name: "layout",
            modelValue: _ctx.active,
            "onUpdate:modelValue": _ctx.onRadioSelect
          }, null, 8, ["label", "value", "modelValue", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", _normalizeProps(_guardReactiveProps({src: _ctx.layoutScreen})), null, 16)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "layout-item-mobile",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRadioSelect && _ctx.onRadioSelect(...args)))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", _normalizeProps(_guardReactiveProps({src: _ctx.layoutScreen})), null, 16)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.name), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.description), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_RadioButton, {
            value: _ctx.type,
            size: "l",
            name: "layout",
            modelValue: _ctx.active,
            "onUpdate:modelValue": _ctx.onRadioSelect
          }, null, 8, ["value", "modelValue", "onUpdate:modelValue"])
        ])
      ]))
}