import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-024449ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-wrap" }
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpeakerButton = _resolveComponent("SpeakerButton")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["speaker-wrap", _ctx.speakerWrapClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
      (_ctx.showMoreButton)
        ? (_openBlock(), _createBlock(_component_SpeakerButton, {
            key: 0,
            isAdmin: false,
            endpointId: _ctx.endpointId,
            hasVideo: _ctx.hasVideo
          }, null, 8, ["endpointId", "hasVideo"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.mute)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          mode: "alert",
          size: "s",
          icon: {spriteUrl: '/image/videoconf-icons.svg', name: 'ic20-micro-mute', color: '--sui-white', width: '20px', height: '20px'} 
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 2))
}