import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b718d64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "popup-wrap" }, {
      default: _withCtx(() => [
        (_ctx.isActive && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["popup-wrap", _ctx.popupClasses]),
              ref: "PopUpRef"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "content", { toggleDropdown: _ctx.toggleClass }, undefined, true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createElementVNode("div", {
      class: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleClass && _ctx.toggleClass(...args))),
      ref: "PopUpButtonRef"
    }, [
      _renderSlot(_ctx.$slots, "button", {}, undefined, true),
      (_ctx.isDefaultButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: _normalizeClass(["icon", {'disabled': _ctx.disabled}]),
            disabled: _ctx.disabled,
            mode: _ctx.buttonMode,
            size: "l",
            icon: _ctx.iconProps,
            "icon-only": ""
          }, null, 8, ["class", "disabled", "mode", "icon"]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}