
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'VadBorder',
    props: {
      hasVad: {
        type: Boolean,
        default: false,
      },
    },
  });
