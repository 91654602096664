import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29573d0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-expanded-settings" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "form-settings" }
const _hoisted_5 = { class: "form-wrap" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "microphone-wrap" }
const _hoisted_8 = { class: "form-wrap" }
const _hoisted_9 = { class: "subtitle" }
const _hoisted_10 = { class: "form-wrap" }
const _hoisted_11 = { class: "subtitle" }
const _hoisted_12 = { class: "account-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Sound = _resolveComponent("Sound")!
  const _component_SignInAccount = _resolveComponent("SignInAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "back-btn",
        icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-shape'},
        size: "s",
        mode: "flat",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDrawer({section: 'settings'})))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('back')), 1)
        ]),
        _: 1
      }, 8, ["icon"]),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('menuList.generalSettings')), 1),
      _createVNode(_component_Button, {
        class: "done-btn",
        size: "s",
        mode: "flat",
        onClick: _ctx.closeDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("form", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('settings.audio')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Select, {
            class: "select microphone",
            modelValue: _ctx.devices.selectedAudioDevice,
            "onUpdate:modelValue": _ctx.updateAudioDevice,
            label: _ctx.t('settings.microphone'),
            options: _ctx.devices.audioDevices,
            key: _ctx.devices.audioDevices,
            searchable: false,
            placeholder: _ctx.t('none'),
            disabled: _ctx.isAudioDisabled,
            id: "microphone"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "placeholder", "disabled"]),
          _createVNode(_component_Sound)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.t('settings.video')), 1),
        _createVNode(_component_Select, {
          class: "select camera",
          modelValue: _ctx.devices.selectedVideoDevice,
          "onUpdate:modelValue": _ctx.updateVideoDevice,
          label: _ctx.t('settings.camera'),
          options: _ctx.devices.videoDevices,
          key: _ctx.devices.videoDevices,
          searchable: false,
          placeholder: _ctx.t('none'),
          disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "placeholder", "disabled"]),
        _createVNode(_component_Select, {
          class: "select quality",
          modelValue: _ctx.devices.selectedQuality,
          "onUpdate:modelValue": _ctx.updateVideoQuality,
          label: _ctx.t('settings.quality'),
          options: _ctx.videoQuality,
          searchable: false,
          placeholder: _ctx.t('none'),
          disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "placeholder", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.t('settings.profile')), 1),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_SignInAccount, {
            class: "account",
            userName: _ctx.auth.displayName,
            email: _ctx.auth.email,
            avatar: _ctx.auth.picture,
            isProfile: true
          }, null, 8, ["userName", "email", "avatar"])
        ])
      ])
    ])
  ]))
}