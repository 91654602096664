import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6372e714"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "speaker-button"
}
const _hoisted_2 = { class: "wrap-setting" }
const _hoisted_3 = {
  key: 0,
  class: "wrap-setting"
}
const _hoisted_4 = {
  key: 1,
  class: "wrap-setting"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_TitleOption = _resolveComponent("TitleOption")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!

  return (_ctx.listMenu.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PopUpWithButton, {
          class: "speaker-popup-wrap",
          isDefaultButton: false
        }, {
          content: _withCtx(({ toggleDropdown }) => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMenu, (item) => {
                return (_openBlock(), _createBlock(_component_Button, {
                  class: "item item-first",
                  mode: "flat",
                  icon: { spriteUrl: '/image/videoconf-icons.svg', name: item.icon, color: '--sui-gray-700', width: '25px', height: '25px'},
                  size: "l",
                  onClick: (() => {item.click(); toggleDropdown()})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["icon", "onClick"]))
              }), 256))
            ]),
            (_ctx.isAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listToggles, (toggle) => {
                    return (_openBlock(), _createBlock(_component_Toggle, {
                      class: "toggle-option",
                      label: toggle,
                      isLabelFirst: false,
                      size: "s"
                    }, null, 8, ["label"]))
                  }), 256))
                ]))
              : _createCommentVNode("", true),
            (_ctx.isAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_TitleOption, {
                    class: "title-setting",
                    title: _ctx.t('speakerMenuList.allow')
                  }, null, 8, ["title"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listAllowUsers, (toggle) => {
                    return (_openBlock(), _createBlock(_component_Toggle, {
                      class: "toggle-option",
                      label: toggle,
                      isLabelFirst: false,
                      size: "s",
                      modelValue: true
                    }, null, 8, ["label"]))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          button: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "bnt-setting",
              mode: "flat",
              size: "s",
              icon: { name: 'ic16-more-ver', color: '--sui-white', width: '20px', height: '20px'} ,
              "icon-only": ""
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}