import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b070032"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-menu" }
const _hoisted_2 = {
  key: 0,
  class: "mobile-menu-wrap"
}
const _hoisted_3 = { class: "btn-cancel-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSettingsItem = _resolveComponent("MenuSettingsItem")!
  const _component_EmojiPanel = _resolveComponent("EmojiPanel")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.modes[_ctx.mode] === _ctx.modes.default)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.listMenuDefault, (item) => {
                return (_openBlock(), _createBlock(_component_MenuSettingsItem, {
                  icon: item.icon,
                  title: item.title,
                  onClick: ($event: any) => (_ctx.changeStatus(item.type))
                }, null, 8, ["icon", "title", "onClick"]))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.modes[_ctx.mode] === _ctx.modes.admin)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.listMenu, (item) => {
                return (_openBlock(), _createBlock(_component_MenuSettingsItem, {
                  icon: item.icon,
                  title: item.title,
                  onClick: item.click
                }, null, 8, ["icon", "title", "onClick"]))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.modes[_ctx.mode] !== _ctx.modes.default)
            ? (_openBlock(), _createBlock(_component_EmojiPanel, {
                key: 2,
                class: "emojis"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              class: "btn-cancel",
              size: "l",
              mode: "outlined",
              onClick: _ctx.closeDrawer
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}