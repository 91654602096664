
  import { computed, defineComponent, PropType } from 'vue';
  import MenuSettingsItem from '@/components/inputs/MenuSettingsItem.vue';
  import { Button } from '@voximplant/spaceui';
  import { closeDrawer, DrawerSection, openDrawer } from '@/store/drawer';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import TogglePanel from '@/components/settings/TogglePanel.vue';
  import EmojiPanel from '@/components/chat/EmojiPanel.vue';
  import { resizeVideoSlot } from '@/store/layout';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition';
  import { meetingStore } from '@/store/meeting';
  import { useToggleAllVideo } from '@/hooks/useToggleAllVideo';

  interface MobileMode {
    audio?: string;
    camera?: string;
    permission?: string;
    default?: string;
    admin?: string;
  }
  const modes: MobileMode = {
    audio: 'audio', // only when isMenu = false
    camera: 'camera', // only when isMenu = false
    permission: 'permission', // only when isMenu = false
    default: 'default', // only when isMenu = true
    admin: 'admin', // only when isMenu = true
  };

  export default defineComponent({
    name: 'MenuSettings',
    components: {
      EmojiPanel,
      TogglePanel,
      MenuSettingsItem,
      Button,
      RadioInput,
    },
    props: {
      mode: {
        type: String as PropType<keyof MobileMode>,
        default: 'audio',
      },
      isMenu: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const { t } = useI18n();
      const { meeting } = meetingStore.getState();
      const endpoints = meeting?.endpointsMap;
      const { isAllVideoDisabled, toggleAllVideo } = useToggleAllVideo(endpoints);
      const titlePermission = t('settings.option');
      const listMenuAdmin = [
        {
          icon: 'ic25-settings-on',
          title: t('menuList.generalSettings'),
          click: () => changeStatus('generalSettings'),
        },
        {
          icon: 'ic25-chat-purple',
          title: t('menuList.chat'),
          click: () => changeStatus('chat'),
        },
        {
          icon: 'ic25-users-purple',
          title: t('menuList.contactList'),
          click: () => changeStatus('contactList'),
        },
        /*{
                icon: 'ic25-portrait-purple',
                title: 'Background and stickers',
              },*/
        {
          icon: 'ic25-layouts-on',
          title: t('menuList.changeLayout'),
          click: () => changeStatus('changeLayout'),
        },
        /*{
                icon: 'ic25-caption-purple',
                title: 'Captions',
              },*/
        /*{
                icon: 'ic25-attachment-on',
                title: 'Attach files',
                type: 'attachFiles',
              },*/
        /*{
                icon: 'ic25-record-purple',
                title: 'Record screen',
                isActivated: false,
              },*/
      ];
      const listMenuDefault = [
        {
          icon: 'ic25-settings-on',
          title: t('menuList.generalSettings'),
          type: 'generalSettings',
        },
        /* {
            icon: 'ic25-portrait-off',
            title: 'Background and stickers',
            isDisabled: true,
          }, */
        {
          icon: 'ic25-layouts-on',
          title: t('menuList.changeLayout'),
          type: 'changeLayout',
        },
        /*{
            icon: 'ic25-attachment-on',
            title: 'Attach files',
            type: 'attachFiles',
          },*/
      ];
      const changeStatus = (status: DrawerSection) => {
        openDrawer({ section: status });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resizeVideoSlot();
      };
      const buttonIcon = computed(() => (isAllVideoDisabled.value ? 'On' : 'Off'));
      const listMenu = computed(() => [
        ...listMenuAdmin,
        {
          icon: `ic25-video-${buttonIcon.value.toLowerCase()}`,
          title: t(`menuList.toggleRemoteAllVideo${buttonIcon.value}`),
          click: () => toggleAllVideo(!isAllVideoDisabled.value),
        },
      ]);

      return {
        t,
        listMenu,
        listMenuDefault,
        closeDrawer,
        changeStatus,
        modes,
        titlePermission,
      };
    },
  });
