import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0bf01a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-item" }
const _hoisted_2 = {
  key: 1,
  class: "emoji"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name" }
const _hoisted_5 = {
  key: 0,
  class: "btns"
}
const _hoisted_6 = {
  key: 1,
  class: "btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestAvatarMock = _resolveComponent("GuestAvatarMock")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.itemClasses)
    }, [
      (_ctx.type === 'inCall')
        ? (_openBlock(), _createElementBlock("div", {
            class: "emoji",
            key: _ctx.lastEmoji
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.emojiClass)
            }, _toDisplayString(_ctx.lastEmoji), 3)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type === 'offline')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.EmojiList.offline), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.avatar)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            class: "avatar",
            src: _ctx.avatar,
            alt: "avatar"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_GuestAvatarMock, {
            key: 3,
            "user-name": _ctx.displayName
          }, null, 8, ["user-name"])),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.displayName), 1)
    ], 2),
    (_ctx.type === 'wait')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "dismis",
            mode: "flat",
            disabled: _ctx.isTokenNotValid,
            size: "s",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setUserDecline({userName: _ctx.userName, displayName: _ctx.displayName})))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('join.userApprove.dismissButton')), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_Button, {
            class: "btn",
            mode: "primary",
            disabled: _ctx.isTokenNotValid,
            size: "s",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.approve(_ctx.userName)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('join.userApprove.admitButton')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type === 'inCall')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.isItMe)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                spriteUrl: "/image/videoconf-icons.svg",
                name: `ic25-contact-mic-${_ctx.devices.audioEnabled ? 'on' : 'off'}`,
                color: "--sui-gray-700"
              }, null, 8, ["name"]))
            : (_openBlock(), _createBlock(_component_Icon, {
                key: 1,
                spriteUrl: "/image/videoconf-icons.svg",
                name: `ic25-contact-mic-${_ctx.userMute ? 'off' : 'on'}`,
                color: "--sui-gray-700"
              }, null, 8, ["name"])),
          _createVNode(_component_Icon, {
            spriteUrl: "/image/videoconf-icons.svg",
            name: _ctx.iconCamName,
            color: "--sui-gray-700"
          }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true)
  ]))
}