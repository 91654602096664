import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-703e6dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio-input" }
const _hoisted_2 = ["id", "value", "name", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.item,
      value: _ctx.item,
      name: _ctx.name,
      checked: _ctx.checked
    }, null, 8, _hoisted_2),
    _createElementVNode("label", {
      class: "radio-input-wrap",
      for: _ctx.item,
      tabindex: "0"
    }, _toDisplayString(_ctx.label || _ctx.item), 9, _hoisted_3)
  ]))
}