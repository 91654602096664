
  import { computed, defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import SpeakerButton from '@/components/panel-buttons/SpeakerButton.vue';

  export default defineComponent({
    name: 'VideoSlotLabel',
    components: {
      SpeakerButton,
      Button,
    },
    props: {
      name: {
        type: String,
        default: 'Your Name',
      },
      showMoreButton: {
        type: Boolean,
        default: false,
      },
      hasVideo: {
        type: Boolean,
        default: false,
      },
      endpointId: {
        type: String,
        default: 'local',
      },
      mute: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'medium',
      },
    },

    setup(props) {
      const speakerWrapClasses = computed(() => ({
        [`speaker-wrap__${props.size}`]: true, // calculate position and max width of name block
      }));

      return {
        speakerWrapClasses,
      };
    },
  });
