
  import { defineComponent } from 'vue';
  import { Icon } from '@voximplant/spaceui';

  export default defineComponent({
    name: 'MenuSettingsItem',
    components: {
      Icon,
    },
    props: {
      icon: {
        type: String,
        default: 'ic25-settings-on',
      },
      title: {
        type: String,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isActivated: {
        type: Boolean,
        default: true,
      },
      color: {
        type: String,
        default: '--sui-purple-500',
      },
    },
  });
