
  import { computed, defineComponent, PropType } from 'vue';
  import { Button } from '@voximplant/spaceui';

  const __default__ = defineComponent({
    name: 'Tooltip',
    components: {
      Button,
    },
    props: {
      text: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      position: {
        type: String as PropType<'center' | 'right'>,
        default: 'center',
      },
    },
    setup(props) {
      const positionStyleMap = {
        center: {
          body: {
            transform: 'none',
          },
          tail: {
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        right: {
          body: {
            transform: 'translateX(-44%)',
          },
          tail: {
            left: '90%',
            transform: 'none',
          },
        },
      };
      const tooltipStyles = computed(() => positionStyleMap[props.position]);

      return {
        tooltipStyles,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c6dd33f2": (_ctx.tooltipStyles.body.transform),
  "be449ac4": (_ctx.tooltipStyles.tail.left),
  "09da5f35": (_ctx.tooltipStyles.tail.transform)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__