import { $mirrorStore } from '@/store/mirrorMedia/index';
import { $devices } from '@/store/devices';
import { ref } from 'vue';
import { throttle } from '@/helpers/throttle';
const THROTTLE_CHECK_SOUND_LEVEL_TIMEOUT = 400;

function detectAudioWorklet() {
  if (window['OfflineAudioContext']) {
    const context = new window['OfflineAudioContext'](1, 1, 44100);
    return context.audioWorklet && typeof context.audioWorklet.addModule === 'function';
  }
  return false;
}

async function prepareAudioNodes(track: MediaStreamTrack): Promise<AudioContext> {
  console.log('audioWorklet helper', 'prepareAudioNodes get', track);
  const oldContext = $mirrorStore.getState().audioContext;
  console.log('audioWorklet helper', 'prepareAudioNodes oldContext', oldContext);
  if (oldContext?.state !== 'closed') await oldContext?.close();

  const audioContext = new AudioContext();
  await audioContext.audioWorklet.addModule('/audioLevelProcessor.js');
  const audioLevelNode = new AudioWorkletNode(audioContext, 'audio-level-processor');
  audioLevelNode.port.onmessage = (event) => {
    const points = document.querySelectorAll('.point') as NodeListOf<HTMLElement>;
    if (points.length && $devices.getState().audioEnabled) changeSoundLevel(event, points);
    else clearSoundLevel(points); // needed for points style reset when sound off
    changeMicrophoneSoundLevel(event);
  };
  const micNode = audioContext.createMediaStreamSource(new MediaStream([track]));
  console.log('audioWorklet helper', 'prepareAudioNodes micNode', micNode);
  micNode.connect(audioLevelNode);
  audioLevelNode.connect(audioContext.destination);
  console.log('audioWorklet helper', 'prepareAudioNodes return', audioContext);
  return audioContext;
}

function changeSoundLevel(event: MessageEvent, points: NodeListOf<HTMLElement>) {
  if (event.data.level * 200 >= 1) {
    points[2].style.height = 'calc(' + Math.ceil(event.data.level * 200) + 'px + 4px)';
  } else {
    points[2].style.height = '4px';
  }
  if (event.data.level * 200 >= 24) {
    points[1].style.height = 'calc(' + Math.ceil(event.data.level * 150) + 'px + 4px)';
    points[3].style.height = 'calc(' + Math.ceil(event.data.level * 150) + 'px + 4px)';
  } else {
    points[1].style.height = '4px';
    points[3].style.height = '4px';
  }
  if (event.data.level * 200 >= 48) {
    points[0].style.height = 'calc(' + Math.ceil(event.data.level * 100) + 'px + 4px)';
    points[4].style.height = 'calc(' + Math.ceil(event.data.level * 100) + 'px + 4px)';
  } else {
    points[0].style.height = '4px';
    points[4].style.height = '4px';
  }
}

const soundLevel = ref(0);
const changeMicrophoneSoundLevel = throttle((event: any) => {
  // data.level is formatted like 0.1...
  soundLevel.value = Math.round(event.data.level * 10) * 10; // convert the value to percentages, use 'Math.round' to round to tenths
  // svg icons have status 20%,40%,60%,80%,100% of volume levels
  if (soundLevel.value > 100) soundLevel.value = 100; // set the maximum value(100%) if data.level > 1
  soundLevel.value = soundLevel.value + (soundLevel.value % 20); // set soundLevel to a multiple of 20 to match the icon status
}, THROTTLE_CHECK_SOUND_LEVEL_TIMEOUT);

function clearSoundLevel(points: NodeListOf<HTMLElement>) {
  points.forEach((point) => (point.style.height = '0'));
}

export { prepareAudioNodes, detectAudioWorklet, soundLevel };
