
  import { computed, defineComponent } from 'vue';
  import { Button, Toggle } from '@voximplant/spaceui';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import { useI18n } from 'vue-i18n';
  import {
    enableEndpointVideoByUser,
    disableEndpointVideoByUser,
    openFullScreen,
    $endpointVideoDisabledByUser,
  } from '@/store/endpointMedia';
  import { meetingStore } from '@/store/meeting';
  import { useStore } from 'effector-vue/composition';

  interface MenuItem {
    icon: string;
    title: string;
    click: () => void;
  }

  export default defineComponent({
    name: 'SpeakerButton',
    components: {
      TitleOption,
      PopUpWithButton,
      Button,
      Toggle,
    },
    props: {
      isAdmin: {
        type: Boolean,
        default: true,
      },
      hasVideo: {
        type: Boolean,
        default: true,
      },
      endpointId: {
        type: String,
        default: 'local',
      },
      listToggles: {
        type: Array,
        default: () => ['Technical Layout', 'Dont’t show this user to other'],
      },
    },
    setup(props) {
      const { t } = useI18n();
      const listMenuDefault: MenuItem[] = [];
      const { meeting } = meetingStore.getState();
      const endpoint = meeting?.endpointsMap.value[props.endpointId];
      const videoDisabledByUser = useStore($endpointVideoDisabledByUser);
      const isVideoDisabledByUser = computed(() =>
        videoDisabledByUser.value.includes(props.endpointId)
      );
      const toggleRemoteVideo = () => {
        if (!isVideoDisabledByUser.value) {
          disableEndpointVideoByUser([props.endpointId]);
        } else {
          enableEndpointVideoByUser([props.endpointId]);
        }
        endpoint?.enableVideo(!isVideoDisabledByUser.value);
      };
      const listMenu = computed(() => {
        let toggle = isVideoDisabledByUser.value ? 'On' : 'Off';
        const activeList = [...listMenuDefault];
        if (props.endpointId !== 'local' && (props.hasVideo || isVideoDisabledByUser.value)) {
          activeList.push({
            icon: `ic25-video-${toggle.toLowerCase()}`,
            title: t(`speakerMenuList.toggleRemoteVideo${toggle}`),
            click: toggleRemoteVideo,
          });
        }
        if (props.hasVideo) {
          activeList.push({
            icon: 'ic25-full-screen',
            title: t('speakerMenuList.fullScreen'),
            click: (): void => {
              openFullScreen(props.endpointId);
            },
          });
        }

        return activeList;
      });

      const listAllowUsers = [
        t('menuList.chat'),
        t('menuList.privateMessages'),
        t('menuList.rename'),
      ];

      return {
        t,
        listMenu,
        listAllowUsers,
        openFullScreen,
      };
    },
  });
