import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-036abfbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-wrap" }
const _hoisted_2 = { class: "item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu-item", {'disabled': _ctx.isDisabled}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        class: "icon",
        spriteUrl: "/image/videoconf-icons.svg",
        name: _ctx.icon,
        color: _ctx.color
      }, null, 8, ["name", "color"]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.isActivated)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "chevron",
          name: "ic20-chevron-right",
          color: "--sui-gray-400"
        }))
      : _createCommentVNode("", true)
  ], 2))
}