
  import { computed, defineComponent, ref } from 'vue';
  import { Button, Icon } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition.cjs';
  import { reactionsStore, updateReaction } from '@/store/reactions';
  import { emojiHand } from '@/store/emojiList';
  import { meetingStore } from '@/store/meeting';
  import { $devices } from '@/store/devices';
  import { $mirrorStore } from '@/store/mirrorMedia';
  import { approve } from '@/store/users';
  import { Conference, Endpoint } from '@voximplant/websdk/modules/conference';
  import GuestAvatarMock from '@/components/accounts/GuestAvatarMock.vue';
  import { $isTokenNotValid, authStore } from '@/store/auth';
  import { setUserDecline } from '@/store/popup';
  import { useVIObservable } from '@/hooks/useVIObservable';

  const EmojiList = {
    offline: '😴',
  } as const;

  const __default__ = defineComponent({
    name: 'ContactItem',
    components: {
      Button,
      Icon,
      GuestAvatarMock,
    },
    props: {
      id: {
        type: String,
      },
      displayName: {
        type: String,
      },
      userName: {
        type: String,
        required: true,
      },
      endpointId: {
        type: String,
        required: true,
      },
      avatar: {
        type: String,
      },
      type: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      // IMPORTANT: Don't use useStore! It affects VIObservers
      const meeting = meetingStore.getState().meeting as Conference;
      const devices = useStore($devices);
      const auth = useStore(authStore);
      const isTokenNotValid = useStore($isTokenNotValid);

      // find the endpointId of the current user
      const endpointsMapArray: Endpoint[] = meeting && Object.values(meeting.endpointsMap.value);
      const endpointId = endpointsMapArray?.find((endpoint) => endpoint.userName === props.userName)
        ?.id;

      // get current endpoint and track it mute status
      const endpoint = endpointId ? meeting?.endpointsMap.value[endpointId] : null;
      const userMute = endpoint && useVIObservable(endpoint.mute);
      const isItMe = computed(() => props.id === auth.value.id);

      // detect video Icons
      const hasVideo = ref(endpoint?.hasVideo.value);
      const mirrorStore = useStore($mirrorStore);
      endpoint?.hasVideo?.watch((status: boolean) => {
        hasVideo.value = status;
      });
      const iconCamName = computed(() => {
        const isActive =
          props.id !== auth.value.id ? hasVideo.value : mirrorStore.value.videoPreview;
        return `ic25-contact-video-${isActive ? 'on' : 'off'}`;
      });

      const itemClasses = computed(() =>
        props.type === 'offline' ? 'item-without-button' : 'item-with-button'
      );
      const emojiClass = computed(() => (userReactions.value?.size ? '' : 'mock'));
      const reactions = useStore(reactionsStore);
      const userReactions = computed(() => reactions.value.reactions[props.userName]);
      const lastEmoji = ref(emojiHand);
      userReactions.value?.forEach((emoji) => {
        lastEmoji.value = reactions.value.emojilist[emoji];
      });

      updateReaction.watch((reaction) => {
        if (reaction.userName === props.userName) {
          if (reaction?.type === 'hand' && reaction.active) {
            // add hand
            return (lastEmoji.value = emojiHand);
          } else if (reaction.active && reaction.type !== 'hand') {
            // add other emoji
            return (lastEmoji.value = reactions.value.emojilist[reaction.type]);
          } else if (
            reaction.type === 'hand' &&
            !reaction.active &&
            userReactions.value?.size !== 1
          ) {
            // remove hand and set last active emoji list
            userReactions.value?.forEach((emoji) => {
              if (emoji !== 'hand') return (lastEmoji.value = reactions.value.emojilist[emoji]);
            });
          } else if (!reaction.active && userReactions.value?.size !== 1) {
            // remove last active emoji
            userReactions.value?.forEach((emoji) => {
              if (emoji !== reaction.type) {
                if (emoji !== 'hand') {
                  return (lastEmoji.value = reactions.value.emojilist[emoji]);
                } else {
                  return (lastEmoji.value = emojiHand);
                }
              }
            });
          } else {
            // set emojiHand to default
            return (lastEmoji.value = emojiHand);
          }
        }
      });
      const contactContentWidth = computed(() => (props.type === 'wait' ? '50%' : '80%'));

      return {
        t,
        isItMe,
        iconCamName,
        itemClasses,
        lastEmoji,
        emojiClass,
        isTokenNotValid,
        EmojiList,
        approve,
        contactContentWidth,
        setUserDecline,
        userMute,
        devices,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7dfcd7d0": (_ctx.contactContentWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__