
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Toggle',
    components: {},
    props: {
      isDisabled: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
      text: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'size-s',
      },
    },
  });
