import {
  $endpointVideoDisabledByUser,
  $isAllIncomingVideoDisabled,
  disableEndpointVideoByUser,
  enableEndpointVideoByUser,
  toggleAllIncomingVideo,
} from '@/store/endpointMedia';
import { Endpoint } from '@voximplant/websdk/modules/conference';
import { SHARING_KINDS } from '@/helpers/pin';
import { VIObservable } from '@/hooks/useVIObservable';
import { useStore } from 'effector-vue/composition';
import { watch } from 'vue';

export const useToggleAllVideo = (endpoints?: VIObservable<Record<string, Endpoint>>) => {
  const isAllVideoDisabled = useStore($isAllIncomingVideoDisabled);
  const endpointVideoDisabledByUser = useStore($endpointVideoDisabledByUser);

  const toggleAllVideo = (status: boolean) => {
    const endpointCandidates =
      Object.values(endpoints?.value || {})
        ?.filter((endpoint) => {
          return !SHARING_KINDS.includes(endpoint.video?.kind || '');
        })
        .map(({ id }) => id) || [];

    if (!isAllVideoDisabled.value) {
      disableEndpointVideoByUser(endpointCandidates);
    } else {
      enableEndpointVideoByUser(endpointCandidates);
    }
    toggleAllIncomingVideo(status);
  };

  watch(endpointVideoDisabledByUser, () => {
    if (isAllVideoDisabled.value) {
      endpointVideoDisabledByUser.value.forEach((id) => {
        endpoints?.value?.[id]?.enableVideo(false);
      });
    }
  });

  watch(isAllVideoDisabled, () => {
    if (!isAllVideoDisabled.value) {
      Object.values(endpoints?.value || {}).forEach((endpoint) => {
        endpoint.enableVideo(true);
      });
    }
  });

  return { isAllVideoDisabled, toggleAllVideo };
};
