import { meetingStore } from '@/store/meeting';
import { changePriorityByPin, priorityByPin, setPriorityByPin } from '@/store/webrtc/endpoints';

export const SHARING_KINDS = ['screen_video', 'sharing'];

const pinByKind = (kind: string, endpointId: string): void => {
  if (SHARING_KINDS.includes(kind) && !priorityByPin.includes(endpointId)) {
    changePriorityByPin(priorityByPin.concat(endpointId));
  }

  if (!SHARING_KINDS.includes(kind) && priorityByPin.includes(endpointId)) {
    unpin(endpointId);
  }
};

const isSharingVideoKind = (kind: string): boolean => SHARING_KINDS.includes(kind);

const unpin = (endpointId: string): void => {
  if (!priorityByPin.includes(endpointId)) return;

  const updatedArr = priorityByPin.filter((id) => id !== endpointId);
  changePriorityByPin(updatedArr);
};

const updatePinState = (endpointIds: string[]) => {
  const { meeting } = meetingStore.getState();

  const newPinnedEndpoints = [...priorityByPin, ...endpointIds].reduce((acc, endpointId) => {
    const kind = meeting?.endpointsMap.value[endpointId]?.video?.kind || '';
    if ((!acc.includes(endpointId) && isSharingVideoKind(kind)) || endpointId === 'local') {
      acc.push(endpointId);
    }
    return acc;
  }, [] as string[]);

  setPriorityByPin(newPinnedEndpoints);
};

export { isSharingVideoKind, pinByKind, unpin, updatePinState };
