import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd3907da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change-layout" }
const _hoisted_2 = {
  key: 0,
  class: "description-container"
}
const _hoisted_3 = {
  key: 1,
  class: "header"
}
const _hoisted_4 = { class: "layout-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LayoutItem = _resolveComponent("LayoutItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Typography, {
            fontType: "header",
            fontSize: "500",
            fontColor: "--gray900"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('layout.change')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Typography, {
            fontType: "text",
            fontSize: "500",
            fontColor: "#000000"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('layout.description')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Typography, {
            fontType: "header",
            fontSize: "700",
            fontColor: "--gray900"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('menuList.changeLayout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Button, {
            class: "done-btn",
            size: "s",
            mode: "flat",
            onClick: _ctx.closeDrawer
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.isMobileScreen)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.activeItem.layoutItems, (item) => {
            return (_openBlock(), _createBlock(_component_LayoutItem, {
              key: item.name,
              name: item.name,
              description: item.description,
              layoutScreen: item.layoutScreen,
              type: item.type,
              active: _ctx.layoutType,
              onActive: ($event: any) => (_ctx.onSelectActive(item.type))
            }, null, 8, ["name", "description", "layoutScreen", "type", "active", "onActive"]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.isMobileScreen)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.layoutItemsMobile, (mobileItem) => {
            return (_openBlock(), _createBlock(_component_LayoutItem, {
              key: mobileItem.name,
              name: mobileItem.name,
              description: mobileItem.description,
              layoutScreen: mobileItem.layoutScreen,
              type: mobileItem.type,
              active: _ctx.layoutType,
              onActive: ($event: any) => (_ctx.onSelectActive(mobileItem.type))
            }, null, 8, ["name", "description", "layoutScreen", "type", "active", "onActive"]))
          }), 128))
        : _createCommentVNode("", true)
    ])
  ]))
}