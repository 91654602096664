
  import { computed, defineComponent } from 'vue';
  import { mockName } from '@/helpers/mockName';

  export default defineComponent({
    name: 'GuestAvatarMock',
    props: {
      userName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const avatarMockName = computed(() => mockName(props.userName));

      return {
        avatarMockName,
      };
    },
  });
