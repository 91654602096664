
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'TitleOption',
    components: {},
    props: {
      title: {
        type: String,
      },
    },
  });
