
  import { defineComponent } from 'vue';
  import Toggle from '@/components/toggles/Toggle.vue';

  export default defineComponent({
    name: 'ToggleWithText',
    components: {
      Toggle,
    },
    props: {
      title: {
        type: String,
      },
      description: {
        type: String,
        default: '',
      },
    },
  });
