
  import { computed, defineComponent } from 'vue';
  import { mockName } from '@/helpers/mockName';
  import VadBorder from '@/components/media/VadBorder.vue';

  const AVATAR_SIZE = '=s262-c';
  export default defineComponent({
    name: 'UserVideoMock',
    components: {
      VadBorder,
    },
    props: {
      name: {
        type: String,
        default: 'No name',
      },
      hasVad: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'medium',
      },
      avatar: {
        type: String,
      },
    },
    setup(props) {
      const avatarUrl = props.avatar?.split('=s');
      const mockAvatarUrl = computed(() => {
        if (avatarUrl && avatarUrl.length > 1) {
          return avatarUrl[0] + AVATAR_SIZE;
        } else {
          return false;
        }
      });
      const videoMockClasses = computed(() => ({
        [`mock-container__${props.size}`]: true, // calculate avatar/mockName size and position
      }));

      return {
        mockName,
        videoMockClasses,
        mockAvatarUrl,
      };
    },
  });
