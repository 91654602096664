import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ced0d512"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "google-account" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "avatar avatar-mock"
}
const _hoisted_4 = { class: "name-wrap" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["avatar-wrap", {'changed': _ctx.isProfile}])
    }, [
      (_ctx.avatar && !_ctx.hasLoadError)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "avatar",
            src: _ctx.avatar,
            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleErrorOnLoad && _ctx.handleErrorOnLoad(...args)))
          }, null, 40, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.avatarMockName), 1))
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "displayName", { userName: _ctx.userName }, () => [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.userName), 1)
      ], true),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.emailInfo), 1)
    ])
  ]))
}